<template>
  <content-loader
    width="660"
    height="475"
    view-box="0 0 660 475"
  >
    <circle
      cx="25"
      cy="112"
      r="15"
    />
    <rect
      x="10"
      y="18"
      rx="0"
      ry="0"
      width="464"
      height="9"
    />
    <rect
      x="497"
      y="10"
      rx="0"
      ry="0"
      width="144"
      height="144"
    />
    <rect
      x="10"
      y="41"
      rx="0"
      ry="0"
      width="464"
      height="9"
    />
    <rect
      x="10"
      y="65"
      rx="0"
      ry="0"
      width="364"
      height="9"
    />
    <rect
      x="50"
      y="98"
      rx="0"
      ry="0"
      width="115"
      height="9"
    />
    <rect
      x="50"
      y="115"
      rx="0"
      ry="0"
      width="115"
      height="10"
    />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'ProfileLoader',
  components: { ContentLoader },
}
</script>

<style scoped>

</style>
