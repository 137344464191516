<template>
  <b-modal
    id="modal-upload-avatar"
    ref="modal-upload-avatar"
    size="md"
    hide-footer
    :title="$t('Upload Avatar')"
  >
    <upload-form
      :loading="loading"
      :progress="progress"
      accept="image/*"
      @onSubmitForm="submitForm"
    />
  </b-modal>
</template>

<script>
import { coreMethods } from '@/mixins'
import UploadForm from '@/views/modules/core/edit/partials/UploadForm.vue'
import {
  BModal,
} from 'bootstrap-vue'
import { setUserData } from '@/auth/utils'
import api from '@/apis/portal'

export default {
  name: 'ModalUploadAvatar',
  components: {
    BModal,
    UploadForm,
  },
  mixins: [coreMethods],
  data() {
    return {
      loading: false,
      progress: 0,
    }
  },
  methods: {
    submitForm(formRules) {
      formRules.validate().then(success => {
        if (success) {
          this.loading = true
          api.upload('uploadAvatar', {
            module: 'Contacts',
            avatar: this.$store.state.portal.formData.file,
          }, progress => {
            this.progress = progress
          }).then(data => {
            this.$store.commit('portal/UPDATE_FORM_DATA', {})
            this.$refs['modal-upload-avatar'].hide()
            setUserData(data)
            this.$emit('onUploadAvatar', data.avatar)
            this.showSuccessNotification(this.$t('Avatar uploaded successfully'))
            this.loading = false
          }).catch(error => {
            this.loading = false
            this.showErrorNotification(error.response.data.error)
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
