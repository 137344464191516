<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <profile-header :header-data="profileData.header" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about company -->
        <b-col
          lg="6"
          cols="12"
          order="1"
          order-lg="1"
        >
          <profile-about :about-data="profileData.userAbout" />
        </b-col>
        <b-col
          lg="6"
          cols="12"
          order="2"
          order-lg="2"
        >
          <profile-company
            v-if="profileData.company"
            :company-data="profileData.company"
          />
        </b-col>
        <!--/ about about company-->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
  <profile-loader v-else />
</template>

<script>
import api from '@/apis/portal'
import { BRow, BCol } from 'bootstrap-vue'

import ProfileLoader from '@/views/modules/core/loader/Profile.vue'
import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'
import ProfileCompany from './ProfileCompany.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    ProfileLoader,
    ProfileHeader,
    ProfileAbout,
    ProfileCompany,
  },
  data() {
    return {
      profileData: {},
    }
  },
  created() {
    api.fetchProfile().then(data => {
      const customer = data.customer_details
      const userAbout = {}
      userAbout[this.$t('First Name')] = customer.firstname
      userAbout[this.$t('Last Name')] = customer.lastname
      userAbout[this.$t('Email')] = customer.email
      userAbout[this.$t('Mobile')] = customer.mobile
      userAbout[this.$t('Phone')] = customer.phone
      let companyData = {}
      const profileData = {
        header: {
          avatar: '',
          username: `${customer.firstname} ${customer.lastname}`,
          designation: customer.email,
          coverImg: require('@/assets/images/profile/user-uploads/timeline.jpg'),
        },
        userAbout,
      }
      if (customer.imagetype) {
        profileData.header.avatar = `data:${customer.imagetype};base64,${customer.imagedata}`
      }
      if (data.company_details) {
        const company = data.company_details
        companyData = {
          avatar: '',
          name: company.accountname,
          phone: company.phone,
          email: company.email1,
          website: company.website,
        }
        if (company.imagetype) {
          companyData.avatar = `data:${company.imagetype};base64,${company.imagedata}`
        }
        profileData.company = companyData
      }
      this.profileData = profileData
    })
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
