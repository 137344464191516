<template>
  <validation-observer
    ref="changePasswordForm"
    #default="{invalid}"
  >
    <b-modal
      id="modal-change-password"
      hide-footer
      :title="$t('Change Password')"
    >
      <b-form
        @submit.prevent="changePassword"
      >
        <!-- password -->
        <b-form-group
          :label="$t('Password')"
          label-for="password-new"
        >
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="Password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password-new"
                v-model="password"
                :type="password1FieldType"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                name="password-new"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="password1ToggleIcon"
                  @click="togglePassword1Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- confirm password -->
        <b-form-group
          label-for="password-confirm"
          :label="$t('Confirm Password')"
        >
          <validation-provider
            #default="{ errors }"
            name="Confirm Password"
            rules="required|confirmed:Password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password-confirm"
                v-model="cPassword"
                :type="password2FieldType"
                class="form-control-merge"
                :state="errors.length > 0 ? false:null"
                name="password-confirm"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="password2ToggleIcon"
                  @click="togglePassword2Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <b-button
            variant="success"
            type="submit"
            :disabled="loading || invalid"
          >
            <b-spinner
              v-if="loading"
              small
            />
            <span v-if="!loading">{{ $t('Save') }}</span>
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
  </validation-observer>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'
import {
  BModal, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BSpinner,
} from 'bootstrap-vue'
import api from '@/apis/api'
import { coreMethods } from '@/mixins'

export default {
  name: 'ModalChangePassword',
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, coreMethods],
  data() {
    return {
      loading: false,
      password: '',
      cPassword: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    changePassword(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.changePasswordForm.validate().then(success => {
        if (success) {
          this.loading = true
          api.changePassword({
            password: this.password,
          }).then(data => {
            api.setToken(data.token)
            this.$bvModal.hide('modal-change-password')
            this.password = ''
            this.cPassword = ''
            this.showSuccessNotification(this.$t('Password changed successfully'))
          }).catch(error => {
            this.loading = false
            this.showErrorNotification(error.response.data.error ? error.response.data.error : '')
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
