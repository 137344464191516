<template>
  <b-card
    :img-src="require('@/assets/images/banner/banner-4.jpg')"
    img-alt="Profile Cover Photo"
    img-top
    class="card-profile"
  >
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
        <b-avatar
          size="114"
          variant="light"
          :src="companyData.avatar"
        />
      </div>
    </div>
    <h3>{{ companyData.name }}</h3>
    <h6 class="text-muted">
      {{ companyData.email }}
    </h6>
    <hr class="mb-2">

    <!-- follower projects rank -->
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h6 class="text-muted font-weight-bolder">
          {{ $t('Phone') }}
        </h6>
        <h3 class="mb-0">
          {{ companyData.phone }}
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">
          {{ $t('Website') }}
        </h6>
        <h3 class="mb-0">
          {{ companyData.website }}
        </h3>
      </div>
    </div>
    <!--/ follower projects rank -->
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
  },
  props: {
    companyData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
