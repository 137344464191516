<template>
  <b-card
    class="profile-header mb-2"
    :img-src="headerData.coverImg"
    img-top
    alt="cover photo"
    body-class="p-0"
  >
    <!-- profile picture -->
    <div class="position-relative">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img">
          <b-img
            :src="avatar"
            rounded
            fluid
            alt="profile photo"
          />
        </div>
        <!-- profile title -->
        <div class="profile-title ml-3">
          <h2 class="text-white">
            {{ headerData.username }}
          </h2>
          <p class="text-white">
            {{ headerData.designation }}
          </p>
        </div>
        <!--/ profile title -->
      </div>
    </div>
    <!--/ profile picture -->

    <!-- profile action -->
    <div class="profile-header-nav">
      <div class="d-flex justify-content-end p-2">
        <b-button
          v-b-modal.modal-change-password
          variant="warning"
          class="mx-2"
        >
          <feather-icon
            icon="LockIcon"
            class="d-block d-md-none"
          />
          <span class="font-weight-bold d-none d-md-block">{{ $t('Change Password') }}</span>
        </b-button>
        <b-button
          v-b-modal.modal-upload-avatar
          variant="success"
          class="mr-2"
        >
          <feather-icon
            icon="UploadCloudIcon"
            class="d-block d-md-none"
          />
          <span class="font-weight-bold d-none d-md-block">{{ $t('Upload Avatar') }}</span>
        </b-button>
      </div>
      <modal-change-password />
      <modal-upload-avatar @onUploadAvatar="uploadAvatar" />
    </div>
    <!--/ profile action -->
  </b-card>
</template>

<script>

import {
  BCard, BImg, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ModalChangePassword from './ModalChangePassword.vue'
import ModalUploadAvatar from './ModalUploadAvatar.vue'

export default {
  components: {
    BCard,
    BButton,
    BImg,
    ModalChangePassword,
    ModalUploadAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      avatar: this.headerData.avatar,
    }
  },
  methods: {
    uploadAvatar(avatar) {
      this.avatar = avatar
    },
  },
}
</script>
